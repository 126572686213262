import React from 'react'
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import './breadcrumb.scss';


export default function Breadcrumb(props)
{
  return (
    <div id="breadcrumb">

      <Link title="Back to home page" to="/">
        <div className="home-link">
          <div className="home-link--spinner">
            <svg>
              <circle cx="50%" cy="50%" r="40px"></circle>
            </svg>
          </div>
        </div>
      </Link>

      {/* <div className="home-link">
        <Link title="Back to home page" to="/"></Link>
      </div> */}
      <div className="headings">
        { (props.parentCrumb!== "" && props.parentCrumb!== undefined) &&
          <span className="parent-crumb">{props.parentCrumb} &#47;</span>
        }
        <h1>{props.heading}</h1>
      </div>
    </div>
  )
}

//PropTypes
Breadcrumb.propTypes = {
  parentCrumb: PropTypes.string,
  heading: PropTypes.string.isRequired,
}