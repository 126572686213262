import React, { Component } from 'react'
import PropTypes from "prop-types";

export default class About extends Component {
  render() {
    return (
      <section id="about-statement">
        <h2>About</h2>
        {
          this.props.aboutItems.map((aboutItem, index) => (
            <p key={index}>{aboutItem}</p>
          ))
        }
      </section>
    )
  }
}

//PropTypes
About.propTypes = {
  aboutItems: PropTypes.array.isRequired,
}