import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import axios from 'axios'
//import { Link } from 'react-router-dom';
//components
import UserProfile from './components/userProfile/UserProfile'
import Home from './components/home/Home'
import Interests from './components/interests/Interests'
import WorkItem from './components/workItem/WorkItem'
import Privacy from './components/privacy/Privacy'
import ScrollToTop from './components/ScrollToTop'
import CommonFns from "./js/commonFns";
//style
import './style/App.scss'


export default class App extends Component
{
  state = {
    profile: {
      first_name: "",
      last_name: "",
      email: "",
      picture: "",
      linkedIn: "",
      address: "",
      title: "",
      age: "",
      strapline: "",
      skills: [],
      achievements: [],
      interests_summary: []
    },
    about:[],
    skills: [],
    work_experience: [],
    education: [],
    interests: {
      summary: "",
      intro: [],
      types: []
    }
  }

  //load config file into state
  componentWillMount()
  {
    axios.get('/config.json').then((response) =>
    {
      let parsedData = parseJson(response.data);
      //console.log("parsedData", parsedData);
      this.setState(parsedData)
    })
  }

  render()
  {
    return (<Router><ScrollToTop>
      <div className="App">
        <UserProfile
          first_name={this.state.profile.first_name}
          last_name={this.state.profile.last_name}
          email={this.state.profile.email}
          degree={this.state.profile.degree}
          picture={this.state.profile.picture}
          linkedIn={this.state.profile.linkedIn}
          address={this.state.profile.address}
          title={this.state.profile.title}
          age={this.state.profile.age}
          strapline={this.state.profile.strapline}
          skills={this.state.profile.skills}
          achievements={this.state.profile.achievements}
          interests={this.state.profile.interests_summary}
        />
        
        <main>
        <Route exact path="/privacy" render={props => (
            <Privacy />
          )} />

          <Route exact path={["/experience", "/"]} render={props => (
            <Home aboutData={this.state.about} workSummaryData={this.state.work_experience} educationData={this.state.education}/>
          )} />

          <Route exact path="/interests" render={props => (
              <Interests interests={this.state.interests} />
          )} />

          <Route path="/experience/:wid" render={props => (
              <WorkItem workItems={this.state.work_experience} workId={props.match.params.wid}/>
          )} />
        </main>
      </div>
    </ScrollToTop></Router>)
  }
}

/**
 * parses the config json file and generates the skills for the profile based on the reference ids
 * @param {json} data 
 */
function parseJson(data)
{
  let newData = {};
  Object.assign(newData, data);

  //1. BUILD SKILL LIST FOR THE PROFILE
  let skillids = data.profile.skills;
  let fskills = data.skills;

  //empty profile skills array
  newData.profile.skills = []

  //generate profile skills by finding the skill by reference id
  for (let i = 0; i < skillids.length; i++) {
    const skillref = skillids[i];

    //find the full skill referenced and assign all the titles
    let fskill = fskills.find(fskill => fskill.id === skillref.id);

    if (fskill !== undefined) {
      let skilltypes = []; //[id,title]

      for (let tref = 0; tref < skillref.type_ids.length; tref++) {
        const typeid = skillref.type_ids[tref];

        //find the title info in the full skill list
        let fType = fskill.types.find(fType => fType.id === typeid);
        if (fType !== undefined) {
          skilltypes.push({
            "id": fType.id,
            "title": fType.title
          })
        }
      }

      newData.profile.skills.push({
        "id": fskill.id,
        "title": fskill.title,
        "types": skilltypes
      })
    }
  }

  //2. BUILD INTEREST FOR THE PROFILE
  newData.profile.interests_summary = newData.interests.types.map(type =>
  {
    return type.title
  });

  //CALCULATE DOB from year
  newData.profile.age = CommonFns.getYearsGivenTwoDates(newData.profile.dob_year).toString()

  return newData;
}