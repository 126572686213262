import { Component } from 'react';
import { withRouter } from 'react-router-dom';


class ScrollToTop extends Component
{
  componentDidUpdate(prevProps)
  {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      //mobile devices scroll
      document.querySelector("main").scrollIntoView({
        block: "start",
        behavior: "smooth"
      });
      //scroll to top of main when navigating between routes
      document.querySelector("main").scrollTo(0, 0);
    }
  }

  render()
  {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);