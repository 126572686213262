import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
//style
import './workSummary.scss'


export default class WorkSummary extends Component {
  render() {
    return (
      <section id="work-summary">
        <h2>Work experience</h2>
        <div id="work-items" className="main-boxes">
          {
            this.props.workItems.map((workItem, wi_index) => (
              <section key={wi_index}>
                <h3>{workItem.date}</h3>
                <ul className="icons">
                  <li><span className="role">{workItem.title}</span></li>
                  <li><span className="company">{workItem.company}</span></li>
                  <li><span className="location">{workItem.location}</span></li>
                </ul>
                <hr/>

                <p className="summary">{workItem.summary}</p>

                <ul id="work-item-skills" className="skill-boxes">
                  {
                    workItem.skills.map((skill, sk_index) => (
                      <li key={sk_index}>{skill}</li>
                    ))
                  }
                </ul>
                <div className="readmore">
                  <Link to={"/experience/" + workItem.id} className="primary-btn">Read more...</Link>
                </div>

                {/* {workItem.company_profile!=="" &&
                  <div class="company-summary"><span>About company:</span> {workItem.company_profile}</div>
                } */}
              </section>
            ))
          }
        </div>
      </section>
    )
  }
}


//PropTypes
WorkSummary.propTypes = {
  workItems: PropTypes.array.isRequired,
}