import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from "prop-types";
import Breadcrumb from '../breadcrumb/Breadcrumb'
//style
import './workItem.scss'

export default class WorkItem extends Component
{
  render() 
  {
    let reqWorkItemId = this.props.workId;//.workId.match.params.wid;
    //let workItemRender; //component to render content

    const workItem = this.props.workItems.find(workItem => workItem.id === reqWorkItemId);
    if (workItem === undefined) { //page does not exist > redirect to home page
      return <Redirect to='/' />
    }

    return (
      <section id="work-detail">
        <Breadcrumb parentCrumb={"Work experience"} heading={workItem.date} />

        <ul className="key-points icons">
          <li><span className="role">{workItem.title}</span></li>
          <li><span className="company">{workItem.company}</span></li>
          <li><span className="location">{workItem.location}</span></li>
        </ul>

        {workItem.company_profile !== "" &&
          <section className="company-profile">
            <h2>Company profile</h2>
            <p>{workItem.company_profile}</p>
          </section>
        }

        <section className="roles">
          <h2>Role</h2>
          <ul>
            {
              workItem.role.map((roleItem, roleIndex) => (
                <li key={roleIndex}>{roleItem}</li>
              ))
            }
          </ul>
        </section>

        <section className="projects">
          <h2>Project highlights</h2>
          {
            workItem.projects.map((projItem, projIndex) => (
              <section key={projIndex} className="project">
                <h3>{projItem.title}</h3>
                <p>{projItem.info}</p>

                <section className="achievements">
                  <h4>Achievements</h4>
                  <ul className="project-achievements">
                    {
                      projItem.achievements.map((achieveItem, achievIndex) => (
                        <li key={achievIndex}>{achieveItem}</li>
                      ))
                    }
                  </ul>
                </section>
              </section>
            ))
          }

        </section>
      </section>
    )
  }
}


//PropTypes
WorkItem.propTypes = {
  workItems: PropTypes.array.isRequired,
  workId: PropTypes.string.isRequired,
}