import React, { Component } from 'react'
import PropTypes from "prop-types";
//import { Link } from 'react-router-dom';
import Breadcrumb from '../breadcrumb/Breadcrumb';
//style
import "./interests.scss";

export default class Interests extends Component
{
  render()
  {
    return (
      <section id="interests">
        <Breadcrumb heading={"Interests"}/>
        {
          this.props.interests.intro.map((introItem, index) => (
            <p key={index}>{introItem}</p>
          ))
        }

        {
          this.props.interests.types.map((interestItem, index) => (
            <section key={index}>
              <h2>{interestItem.title}</h2>
              <p>{interestItem.info}</p>

              <div className="images">
                {
                  interestItem.images.map((imgItem, index) => (
                    <div key={index}>
                      <img src={"/assets-dyna/interests/thumbs/" + imgItem} alt={imgItem} />
                    </div>
                  ))
                }
              </div>
            </section>
          ))
        }
      </section>
    )
  }
}


//PropTypes
Interests.propTypes = {
  interests: PropTypes.object.isRequired,
}