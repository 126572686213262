import React from 'react'
import WorkSummary from '../workSummary/WorkSummary'
import Education from './../education/Education'
import About from './../about/About';
import PropTypes from "prop-types";
//style
import './home.scss'

export default function Home(props) {
  return (
    <section id="home-page">
      <About aboutItems={props.aboutData} />
      <WorkSummary workItems={props.workSummaryData} />
      <Education eduItems={props.educationData} />
    </section>
  )
}


//PropTypes
Home.propTypes = {
  aboutData: PropTypes.array.isRequired,
  workSummaryData: PropTypes.array.isRequired,
  educationData: PropTypes.array.isRequired,
}