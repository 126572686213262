import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
//style
import './profile.scss'

export default class UserProfile extends Component
{

  render()
  {
    return (
      <aside id="profile">
        <div id="profile-pic">
          <Link to="/">
            <img src={`/assets-dyna/${this.props.picture}.jpg`} alt="face" />
          </Link>
        </div>
        
        <section id="profile-about">
          <h1>
            <div>{this.props.first_name}</div>
            <div>{this.props.last_name}</div>
          </h1>
          <ul id="profile-about-personal" className="icons">
            <li>
              <span className="job">{this.props.title}</span>
            </li>
            <li>
              <span className="degree">{this.props.degree}</span>
            </li>
            <li>
              <span className="email"><a href={"mailto:" + this.props.email} target="_blank" rel="noopener noreferrer">
                {this.props.email}</a>
              </span>
            </li>
            <li>
              <span className="linkedIn">
                <a href={this.props.linkedIn} target="_blank" rel="noopener noreferrer">hamzahhusseini</a>
              </span>
            </li>
            <li>
              <span className="address">{this.props.address}</span>
            </li>
            <li>
              <span className="age">{this.props.age + " years old"}</span>
            </li>
          </ul>
          <br />
          <q id="profile-about-strapline">{this.props.strapline}</q>
        </section>

        <section id="profile-skills">
          <h2>
            <span className="small">Recent</span> skills
          </h2>
          {
            this.props.skills.map((skill, index) => (
              <div key={index}>
                {/* <h3 key={index}>{skill.title}</h3> */}
                <ul className="skill-boxes">
                  {
                    skill.types.map((skillType, tIndex) => (
                      <li key={tIndex}>{skillType.title}</li>
                    ))
                  }
                </ul>
              </div>
            ))
          }
        </section>

        <section id="profile-achievements">
          <h2>
            <span className="small">Recently</span> achieved
          </h2>
          <ul className="icons">
            {
              this.props.achievements.map((achievement, index) => (
                <li key={index}>
                  <span>{achievement}</span>
                </li>
              ))
            }
          </ul>
        </section>

        <section id="profile-interests">
        <h2 className="read-more">
            <Link to="/interests" title="See more interests">
              Interests
            </Link>
          </h2>
          <ul className="icons">
            {
              this.props.interests.map((interest, index) => (
                <li key={index}>
                  <span>{interest}</span>
                </li>
              ))
            }
          </ul>
        </section>

        <footer id="site-footer">
          <div id="site-footer-copyr">&copy; {new Date().getFullYear()} Hamzah Husseini</div>
          <Link id="site-footer-privacy" to="/privacy">Privacy</Link> | <i> Site created using <b>React</b></i>
        </footer>
      </aside>
    )
  }
}

//PropTypes
UserProfile.propTypes = {
  achievements: PropTypes.array.isRequired,
  picture: PropTypes.string.isRequired,
  linkedIn: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  age: PropTypes.string.isRequired,
  strapline: PropTypes.string.isRequired,
  skills: PropTypes.array.isRequired,
  interests: PropTypes.array.isRequired,
}
//handleClose: PropTypes.func.isRequired,