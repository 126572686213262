import React, { Component } from 'react'
import PropTypes from "prop-types";
//style
import "./education.scss";

export default class Education extends Component
{
  render()
  {
    return (
      <section id="education">
        <h2>Education</h2>
        <div id="edu-items" className="main-boxes">
          {
            this.props.eduItems.map((eduItem, index) => (
              <section key={index}>
                <h3>{eduItem.date}</h3>

                <ul className="icons">
                  <li><span className="qualification">{eduItem.qualification}</span></li>
                  <li><span className="location">{eduItem.institution}</span></li>
                </ul>
                {eduItem.highlights.length > 0 &&
                  <React.Fragment>
                    <hr />
                    <ul className="highlights">
                      {
                        eduItem.highlights.map((highlight, eduItemIndex) => (
                          <li key={eduItemIndex}>{highlight}</li>
                        ))
                      }
                    </ul>
                  </React.Fragment>
                }
              </section>
            ))
          }
        </div>
      </section>
    )
  }
}

//PropTypes
Education.propTypes = {
  eduItems: PropTypes.array.isRequired,
}